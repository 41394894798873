<template>
  <div class="serverTop">
    <div class="leftBox">
      <h1 id="word01">{{title}}</h1>
      <span id="word02">{{wera}}</span>
    </div>
    <div class="rightBox">
      <span id="word03" :class="qi=='true'? 'qi':''">{{jiage}}</span>
      <span id="word04">(限时优惠中)</span>
    </div>
  </div>
</template>

<script>
export default {
  props:["title","wera","jiage","qi"]
}
</script>

<style scoped lang="scss">
.serverTop{
  width: 75.125rem /* 1202px -> 75.125rem */;
  height: 6.625rem /* 106px -> 6.625rem */;
  padding-left: 1.875rem /* 30px -> 1.875rem */;
  padding-right: 1.875rem /* 30px -> 1.875rem */;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 0.1875rem /* 3px -> 0.1875rem */ 0.1875rem /* 3px -> 0.1875rem */ rgba($color: #000000, $alpha: 0.07);
  border-radius: 0.625rem /* 10px -> 0.625rem */;
  position: relative;
  top: -3.125rem /* -50px -> -3.125rem */;
  background-color: #fff;
  .leftBox{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    #word01{
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #333;
    }
    #word02{
      font-size: 1.25rem /* 20px -> 1.25rem */;
      color: #666;
    }
  }
  .rightBox{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    #word03{
      font-size: 1.75rem /* 28px -> 1.75rem */;
      color: #EE0000;
    }
    #word03::before{
      content: '￥';
      font-size: 2.125rem /* 34px -> 2.125rem */;
    }
    .qi::after{
      content: '起';
      font-size: 1.375rem /* 22px -> 1.375rem */;
      color: #666;
      margin-left: 0.625rem /* 10px -> 0.625rem */;
    }
    #word04{
      font-size: 1rem /* 16px -> 1rem */;
      color: #FE7A1E;
    }
  }
}
</style>