import { render, staticRenderFns } from "./serverTop.vue?vue&type=template&id=5c077e21&scoped=true&"
import script from "./serverTop.vue?vue&type=script&lang=js&"
export * from "./serverTop.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./serverTop.vue?vue&type=style&index=0&id=5c077e21&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5c077e21",
  "24861c50"
  
)

export default component.exports